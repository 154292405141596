document.addEventListener('DOMContentLoaded', function() {
  var orgsList = document.getElementById('org-list');
  var orgCount = document.getElementById('num-orgs');
  var pluralSpan = document.getElementById('plural');
  var inSpan = document.getElementById('in-span');
  var selects = {
    'county': document.getElementById('county-picker'),
    'type': document.getElementById('type-picker'),
  };
  var labels = {
    'county': document.getElementById('selected-county'),
    'type': document.getElementById('selected-type'),
  };
  var matchFunction = {
    'county': (function(org, value) { return (org.county === value); }),
    'type': (function(org, value) { return (org.serviceTypesLower.indexOf(value) >= 0); }),
  };
  var filters = {
    'county': "",
    'type': "",
  };
  var orgs = [];

  function _getIconForService(service) {
    switch(service) {
      case 'Addiction support':
        return 'fa-handshake';
      case 'Childcare':
        return 'fa-child';
      case 'Deliveries':
        return 'fa-truck';
      case 'Education':
        return 'fas fa-graduation-cap';
      case 'Food':
        return 'fa-utensils';
      case 'Healthcare':
        return 'fa-medkit';
      case 'Housing/safe shelter':
        return 'fa-home';
      case 'Legal support':
        return 'fa-briefcase';
      case 'Mental health':
        return 'fa-user-shield';
      case 'PPE/masks/medical supplies':
        return 'fa-head-side-mask';
      case 'Senior care':
        return 'fa-universal-access';
      case 'Service navigation':
        return 'fa-users-cog';
      default:
        return 'fa-plus-circle';
    }
  }

  function _cleanPhoneNumber(phoneNumberString) {
    return ('' + phoneNumberString).replace(/\D/g, '');
  }

  function _formatPhoneNumber(phoneNumberString) {
    var cleaned = _cleanPhoneNumber(phoneNumberString);
    if (cleaned.length === 11 && cleaned[0] === '1') {
      cleaned = cleaned.slice(1);
    }
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return cleaned;
  }

  function _capFirst(s) {
    if (s.length < 1) {
      return s;
    }
    return s[0].toUpperCase() + s.slice(1);
  }

  function _sanitizeOrg(org) {
    if (!org.serviceAvailability) {
      return null;
    }

    org.otherServiceTypes = org.ifOtherPleaseDescribe ? org.ifOtherPleaseDescribe.split(',').map(function(s) { return _capFirst(s.trim()); }) : [];
    org.serviceTypes = org.serviceType.split(',').map(function(s) { return _capFirst(s.trim()); });
    org.serviceTypesLower = org.serviceTypes.map(function(s) { return s.toLowerCase(); });
    org.address = org.address && org.address.replace('\n', '<br>');
    org.phoneNumber = _cleanPhoneNumber(org.servicesContactPhoneNumber);

    return org;
  }

  function _unique(x, i, a) {
    return a.indexOf(x) == i;
  }

  function _countySort(a, b) {
    if (a === 'Statewide') {
      return -1;
    } else if (b === 'Statewide') {
      return 1;
    }
    return a < b ? -1 : 1;
  }

  function _serviceSort(a, b) {
    if (a === 'other') {
      return 1;
    } else if (b === 'other') {
      return -1;
    }
    return a < b ? -1 : 1;
  }

  function _getCounties(orgs) {
    return orgs.map(function(o) { return o.county; }).filter(_unique).sort(_countySort);
  }

  function _getTypes(orgs) {
    return orgs.map(function(o) { return o.serviceTypesLower; }).flat().filter(_unique).sort(_serviceSort);
  }

  function _updateSelects() {
    Object.keys(filters).forEach(function(filterKey) {
      var shownOrgs = _filterOrgs(filterKey).toShow;
      var availableOptions = {
        'county': _getCounties(shownOrgs),
        'type': _getTypes(shownOrgs),
      };

      var selectOptions = selects[filterKey].getElementsByClassName('select-opt');
      for (var i = 0; i < selectOptions.length; i++) {
        if (availableOptions[filterKey].indexOf(selectOptions[i].value) >= 0) {
          selectOptions[i].classList.remove('hidden');
        } else {
          selectOptions[i].classList.add('hidden');
        }
      }
    });
  }

  function _filterOrgs(ignoreKey) {
    var toHide = [], toShow = [];

    orgs.forEach(function(org) {
      var shouldShow = Object.keys(filters).every(function(filterKey) {
        return (
          filterKey === ignoreKey ||
          filters[filterKey] === '' ||
          matchFunction[filterKey](org, filters[filterKey])
        );
      });
      (shouldShow ? toShow : toHide).push(org);
    });

    return {
      toHide: toHide,
      toShow: toShow,
    };
  }

  function _updateFilteredList() {
    var filterResults = _filterOrgs();

    filterResults.toShow.forEach(function(org) {
      org._elem.classList.remove('hidden');
      org._elem.classList.add('visible');
    });
    filterResults.toHide.forEach(function(org) {
      org._elem.classList.add('hidden');
      org._elem.classList.remove('visible');
    });

    _updateSelects(filterResults.toShow);
    orgCount.innerText = filterResults.toShow.length;
    if (filterResults.toShow.length === 1) {
      pluralSpan.classList.add('hidden');
    } else {
      pluralSpan.classList.remove('hidden');
    }
  }

  function _onSelectChange(prefix) {
    return function(e) {
      filters[prefix] = e.target.value;
      labels[prefix].innerText = selects[prefix].options[selects[prefix].selectedIndex].text;
      console.log(prefix, e.target.value);
      if (prefix === 'county') {
        if (e.target.value === 'Statewide') {
          inSpan.classList.add('hidden');
        } else {
          inSpan.classList.remove('hidden');
        }
      }
      _updateFilteredList();
    };
  }

  function _renderSelectOptions(prefix, options) {
    options.forEach(function(option) {
      var optionElem = document.createElement('option');
      optionElem.className = "select-opt";
      optionElem.value = option;
      optionElem.innerText = option;
      selects[prefix].appendChild(optionElem);
    });

    selects[prefix].addEventListener('change', _onSelectChange(prefix));
  }

  function _createElements(org) {
    var orgElem = document.createElement('li');
    orgElem.className = "organization visible";
    orgElem.innerHTML = (
      '<div class="header">' +
        '<h2>' + org.organizationName + '<span class="light">' + (org.county === 'Statewide' ? ' ' : ' in ') + org.county + '</span></h2>' +
        '<div class="services">' +
          (org.serviceTypes.concat(org.otherServiceTypes).reduce(function(html, serviceType) {
            if (serviceType === 'Other') {
              return html;
            }
            return (
              html +
              '<div class="service"><i class="fa ' + _getIconForService(serviceType) + '"></i>' + serviceType + '</div>'
            );
          }, '')) +
         '</div>' +
      '</div>' +
      '<div class="org-body">' +
        '<div class="about">' +
          ( org.serviceDescription ? (
            '<h6>About</h6>' +
            '<p>' + org.serviceDescription + '</p>'
          ) : '') +
          ( org.serviceAvailability ? (
            '<h6>Resource Availability</h6>' +
            '<p class="small">' + org.serviceAvailability + '</p>'
          ) : '') +
        '</div>' +
        '<div class="contact-info">' +
          '<h6>Contact Info</h6>' +
          ((org.address || org.streetNumberAndName) ? (
            '<p class="small">' +
              '<i class="fa fa-home"></i>' +
              ( org.address ? (
                  org.address
                ) : (
                  ( org.servicesContactName ? (org.servicesContactName + '<br>') : '') +
                  org.streetNumberAndName +
                  ( (org.cityOrTown && org.zipCode) ? ( '<br>'+ org.cityOrTown + ', WV ' + org.zipCode ) : '')
                )
              ) +
            '</p>'
          ) : '') +
          (org.organizationWebsite ? ( '<p class="small"><a href="' + org.organizationWebsite + '" target="_blank"><i class="fa fa-globe"></i>Website</a></p>') : '') +
          (org.facebookPageOrGroupLink ? ( '<p class="small"><a href="' + org.facebookPageOrGroupLink + '" target="_blank"><i class="fab fa-facebook"></i>Facebook</a></p>') : '') +
          ( org.phoneNumber ? ( '<p class="small"><a href="tel:' + org.phoneNumber + '"><i class="fa fa-phone"></i>' + _formatPhoneNumber(org.phoneNumber) + '</a></p>') : '') +
        '</div>' +
      '</div>'
    );

    org._elem = orgElem;
    return org;
  }

  function loadOrgs() {
    var database = firebase.database();

    firebase.database().ref('/organizationsDatabase/').once('value').then(function(snapshot) {
      snapshot.forEach(function(j) {
        var orgOrNull = _sanitizeOrg(j.val());
        if (orgOrNull) {
          orgs.push(orgOrNull);
        }
      });

      if (orgs.length > 0) {
        orgs = orgs.map(_createElements);
        orgs.forEach(function(org) {
          orgsList.appendChild(org._elem);
        });
      } else {
        orgsList.remove();
      }

      var allCounties = _getCounties(orgs);
      var allServiceTypes = _getTypes(orgs);

      _renderSelectOptions('county', allCounties);
      _renderSelectOptions('type', allServiceTypes);
      orgCount.innerText = orgs.length;

      var rootElem = document.getElementsByClassName('orgs-loading')[0];
      rootElem.className = rootElem.className.replace('orgs-loading', '');
    });
  }

  loadOrgs();
});
